import React from 'react';

export const NavBarHeightContext = React.createContext(0);
export const NavBarElementSetterContext = React.createContext(0);

let ResizeObserver;
if (typeof window !== 'undefined') {
  if (window.ResizeObserver) ResizeObserver = window.ResizeObserver;
  else ResizeObserver = require('resize-observer-polyfill').default; // eslint-disable-line global-require
}

export const NavBarHeightContextController = ({ children }) => {
  const element = React.useRef();
  const [height, setHeight] = React.useState();

  React.useEffect(() => {
    if (!element.current) {
      setHeight(0);
      return () => {};
    }

    const handleResize = () => {
      if (element.current) setHeight(element.current.getBoundingClientRect().height);
    };

    if (ResizeObserver && element.current) {
      const resizeObserver = new ResizeObserver(handleResize);

      resizeObserver.observe(element.current);

      return () => resizeObserver.disconnect();
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <NavBarElementSetterContext.Provider
      value={(e) => {
        element.current = e;
      }}
    >
      <NavBarHeightContext.Provider value={height}>{children}</NavBarHeightContext.Provider>
    </NavBarElementSetterContext.Provider>
  );
};
